import React, { useEffect, useState } from "react";
import Layout from '../../components/layout';
import Seo from "../../components/seo";
import { saveAnswerWithRefIdAndValue } from '../../utils/utils';

const Question3 = (props) => {
  // console.log(props)
  const canonicalUrl = `/quiz/q-3`;
  const seo = {};
  const [allRefIdQ3, setAllRefIdQ3] = useState(null);
  const [thisQuestion, setThisQuestion] = useState(() => {
    if (typeof window === "undefined") return {};
    // getting stored value
    const questions = JSON.parse(localStorage?.getItem('questions'));
    return questions[2] || {};
  });
  const [selectedOption, setSelectedOption] = useState(null);
  useEffect(() => {
    // setQ3(JSON.parse(localStorage?.getItem('questions'))[2]);
    const questions = JSON.parse(localStorage?.getItem('questions'));
    if (questions) {
    } else {
      window.location.href = "/";
    }
  }, [selectedOption, thisQuestion]);
  // 
  const selectOption = (e) => {
    console.log(e)
    const ele = e.target;
    const value = ele.value;
    const refId = e.target.dataset.refId;
    //
    setSelectedOption(value);
    //
    saveAnswerWithRefIdAndValue(refId, value);
  }
  return (
    <Layout>
      <Seo title={ `Quiz ` } canonical={ canonicalUrl } seo={seo} />
      <section className="bg-white my-5">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
              <p class="mb-0 text-start ps-1">70% completed</p>
              <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: 70 + '%' }}></div>
                </div>
                <h3 className="my-4" style={{ fontSize: 25 + 'px', fontWeight: 300 }}>
                { thisQuestion?.content }
                </h3>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center mt-5 offset-1">
              <div class="col-md-11">
              {
                thisQuestion?.question_type === 'yes/no' ?
                  <div>
                    <div class="form-check">
                    <input data-ref-id={ thisQuestion?.ref_id } value='Yes' class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                    checked={ selectedOption == 'Yes' } 
                    onChange={ selectOption }/>
                    <label class="form-check-label" for="flexRadioDefault1">
                    Yes
                    </label>
                  </div>
                    <div class="form-check">
                    <input data-ref-id={ thisQuestion?.ref_id } value='No'  class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                    checked={ selectedOption == 'No' }  onChange={ selectOption }/>
                    <label class="form-check-label" for="flexRadioDefault2">
                    No
                    </label>
                  </div>
                </div>
              :
                thisQuestion?.children?.map(children => {
                  <div class="form-check">
                    <input data-ref-id={ thisQuestion?.ref_id } value={ children?.ref_id }  class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                    checked={ selectedOption == children?.ref_id }  onChange={ selectOption }/>
                    <label class="form-check-label" for="flexRadioDefault1">
                    { children?.content }
                    </label>
                  </div>
                })
              }
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center mt-5">
              <div class="col-md-3"><div class="d-grid gap-2"><a href="/quiz/q-2" class="btn btn-outline-primary py-3 fw-bold" type="button">Previous</a></div></div>
              <div class="col-md-3">
                <div class="d-grid gap-2">
                    <a href="/quiz/q-4" class="btn btn-warning py-3 fw-bold" type="button">Next</a>
                </div>
              </div>
            </div>
          </div>
        </section>
    </Layout>
  )
}

export default Question3;
